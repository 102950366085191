import React, { useState } from "react";

import "./contacto.css";
import Header from "../subComponentes/header/Header";
import Footers from "../subComponentes/footer/Footers";
import { rutaAPI } from "../../config";

export default function Contacto() {
  const [mensaje, setMensaje] = useState("");
  const [tipoMensaje, settipoMensaje] = useState("");
  const [emailError, setEmailError] = useState("");
  const [telefonoError, setTelefonoError] = useState("");
  const [nombreError, setNombreError] = useState("");
  const [empresaError, setEmpresaError] = useState("");



  // Validar Nombre
  const validarNombre = (nombre) => {
    const regex = /^[A-Za-z\s]{3,}$/;  // 3 dígitos, seguido de un espacio y 6 dígitos
    if (!regex.test(nombre) && nombre) {
      setNombreError(
        "El nombre debe  contener al menos 3 letras, sin numeros ni signos "
      );
    } else {
      setNombreError("");
    }
  }; 

  const handleChangeNombre = (e) => {
    const nombre = e.target.value;
    validarNombre(nombre);
  };

   // Validar Empresa
   const validarEmpresa = (empresa) => {
    const regex = /^[A-Za-z\s]{3,}$/;  // 3 dígitos, seguido de un espacio y 6 dígitos
    if (!regex.test(empresa) && empresa) {
      setEmpresaError(
        "La empresa debe  contener al menos 3 letras, sin numeros ni signos "
      );
    } else {
      setEmpresaError("");
    }
  }; 

  const handleChangeEmpresa = (e) => {
    const nombre = e.target.value;
    validarEmpresa(nombre);
  };



  const validarEmail = (email) => {
    const regex =
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|net|org|edu|gov|info|uy|ar|cl)$/;
    if (!regex.test(email) && email) {
      setEmailError(
        "El correo debe ser válido y terminar en .com, .net, .org, etc."
      );
    } else {
      setEmailError("");
    }
  };

  const handleChangeEmail = (e) => {
    const email = e.target.value;
    validarEmail(email);
  };

  // Validar teléfono
  const validarTelefono = (telefono) => {
    const regex = /^\d{6,}$/; // 3 dígitos, seguido de un espacio y 6 dígitos
    if (!regex.test(telefono) && telefono) {
      setTelefonoError(
        "El teléfono debe  contener al menos 9 dígitos "
      );
    } else {
      setTelefonoError("");
    }
  };

  const handleChangeTelefono = (e) => {
    const telefono = e.target.value;
    validarTelefono(telefono);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;

    const formData = {
      nombre: document.getElementById("nombre").value,
      empresa: document.getElementById("empresa").value,
      email: document.getElementById("email").value,
      telefono: document.getElementById("telefono").value,
      mensaje: document.getElementById("mensaje").value,
    };

    try {
      const response = await fetch(`${rutaAPI}/send`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setMensaje("Mensaje enviado con éxito");
        settipoMensaje("exito");
        form.reset();

        setTimeout(() => {
          setMensaje("");
          settipoMensaje("");
        }, 3000);
      } else {
        setMensaje("Hubo un problema al enviar el mensaje");
        settipoMensaje("error");

        setTimeout(() => {
          setMensaje("");
          settipoMensaje("");
        }, 3000);
      }
    } catch (error) {
      console.error("Error:", error);
      setMensaje("Hubo un problema al enviar el mensaje");
      settipoMensaje("error");
    }
  };

  return (
    <div>
      <Header />
      <div className="encabezadoForm">
        <h2 className="text-center">Contáctanos</h2>
        <p className="text-center">
          ¿Tienes alguna consulta o quieres obtener mas informacion? Rellena el
          formulario y nos pondremos en contacto contigo.
        </p>
      </div>
      <section id="contacto" className="container-flex">
        <form className="form pt-3" onSubmit={handleSubmit}>
          <div className="mb-2">
            <label htmlFor="nombre" className="form-label"></label>
            <input
              type="text"
              className="form-control"
              id="nombre"
              placeholder="Tu nombre"
              minLength="3"
              required
              title="El nombre debe tener al menos 3 caracteres"
              onChange={handleChangeNombre}
            />{nombreError && <p className="text-danger">{nombreError}</p>}
          </div>
          <div className="mb-2">
            <label htmlFor="empresa" className="form-label"></label>
            <input
              type="text"
              className="form-control"
              id="empresa"
              placeholder="Empresa"
              minLength="3"
              title="La empresa debe tener al menos 3 caracteres"
              onChange={handleChangeEmpresa}
            />{empresaError && <p className="text-danger">{empresaError}</p>}
          </div>
          <div className="mb-2">
            <label htmlFor="email" className="form-label"></label>
            <input
              type="email"
              className="form-control"
              id="email"
              placeholder="tuemail@ejemplo.com"
              pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|net|org|edu|gov|info|uy|ar|cl)$"
              required
              title="El email debe tener un formato válido"
              onChange={handleChangeEmail}
            />{" "}
            {emailError && <p className="text-danger">{emailError}</p>}
          </div>
          <div className="mb-2">
            <label htmlFor="telefono" className="form-label"></label>
            <input
              type="tel"
              className="form-control"
              id="telefono"
              placeholder="Ej: +598 12345678"
              pattern="^\+?\d{8,15}$"
              title="Debe tener entre 8 y 15 dígitos."
              onChange={handleChangeTelefono}
            />{" "}
            {telefonoError && <p className="text-danger">{telefonoError}</p>}
          </div>
          <div className="mb-5">
            <label htmlFor="mensaje" className="form-label"></label>
            <textarea
              className="form-control"
              id="mensaje"
              rows="5"
              placeholder="Escribe tu mensaje aquí"
              required
              minLength="10"
              title="El mensaje debe tener al menos 10 caracteres"
            ></textarea>
          </div>
          {mensaje && (
            <p
              className={`alert ${
                tipoMensaje === "exito" ? " alert-success" : "alert-danger"
              }`}
            >
              {mensaje}
            </p>
          )}
          <div className="d-flex justify-content-center p-4">
            <button type="submit" className="btn btn-primary button">
              Enviar mensaje
            </button>
          </div>
        </form>
      </section>
      <Footers />
    </div>
  );
}
