import React, { useContext, useState } from "react";
import "./header.css";
import { styled } from "styled-components";

import BurgerButton from "./burguerButton/BurgerButton";

/*HOOKS*/
import { useHamburger } from "../../../hooks/hookHamburger";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import insta from "../../../img/instagram.png";
import youtube from "../../../img/youtube.png";
import facebook from "../../../img/facebook.png";
import ngdesign from "../../../img/ngDesign.png";

export default function Header() {
  const { isClicked, toggleMenu } = useHamburger();

  return (
    <header className="header">
      <div className="encabezado">
        <div className="title-links">
          <div className="logo">
            <img src={ngdesign} alt="/#" />
          </div>
        </div>
        <ul className="links">
          <li>
            <a href="/#">Home</a>
          </li>
          <li>
            <a href="/#precios">Planes</a>
          </li>
          <li>
            <a href="/contacto">Contacto</a>
          </li>
          <li>
            <a href="/#servicios">Servicios</a>
          </li>
        </ul>
        <div className="redes">
          <img src={insta} alt="/#" loading="lazy" />
          <img src={facebook} alt="/#" loading="lazy" />
          <img src={youtube} alt="/#" loading="lazy" />
        </div>
      </div>
      {/* -------------------------------------*/}
      <div className="encabezado-movil">
        <div className="logo">
          <img src={ngdesign} alt="/#" loading="lazy" />
        </div>
        <div className="burgerButton" onClick={toggleMenu}>
          <BurgerButton />
        </div>
      </div>

      <ul className={`${isClicked ? "actives" : "desactive"} `}>
        <li>
          <a href="/#">Home</a>
        </li>
        <li>
          <a href="#precios">Planes</a>
        </li>
        <li>
          <a href="/contacto">Contacto</a>
        </li>
        <li>
          <a href="#servicios">Servicios</a>
        </li>
      </ul>
    </header>
  );
}
