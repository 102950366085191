import React, { useEffect } from "react";

import "./featured.css";

import escritorio from "../../../img/escritorio.jpg";

export default function Featured() {
  
  useEffect(() => {
    const handleScrollt = () => {
      const elementos = document.querySelectorAll(".aos-animate");
      const elementosRight = document.querySelectorAll(".aos-animate-right");
      const windowHeight = window.innerHeight;
     
     
      elementos.forEach((elemento, index) => {
        const rect = elemento.getBoundingClientRect();
        const isInCenter =
        
        rect.top <= windowHeight / 1.3 && rect.bottom >= windowHeight / 1.3;

        if (isInCenter && !elemento.classList.contains("animated")) {
          elemento.style.animation = `traslado 400ms ease-in-out forwards`;
          elemento.style.animationDelay = `${index * -200}ms`;
          elemento.classList.add("animated");
        }
      });

      elementosRight.forEach((elemento, index) => {
        const rect = elemento.getBoundingClientRect();
        const isInCenter =
          rect.top <= windowHeight / 1.3 && rect.bottom >= windowHeight / 1.3;

        if (isInCenter && !elemento.classList.contains("animated")) {
          elemento.style.animation = `trasladoRight 400ms ease-in-out forwards`;
          elemento.style.animationDelay = `${index * -200}ms`;
          elemento.classList.add("animated");
        }
      });
    };
    window.addEventListener("scroll", handleScrollt);
    return () => {
      window.removeEventListener("scroll", handleScrollt);
    };
  }, []);

  return (
    <section className="featured">
      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6  aos-animate">
            <div className="titleFeatured">
              <h6 className="title-small">Acerca de nuestro Trabajo</h6>
              <h1 className="title-blue">
                Servicios premium y soluciones personalizadas.
              </h1>
            </div>
            <p>
              Con más de 10 años de experiencia en diseño web y desarrollo
              fullstack, ofrecemos soluciones creativas y eficaces. Ya sea para
              negocios locales o proyectos internacionales, garantizamos sitios
              modernos, responsivos y optimizados.
            </p>
            <div className="media-element d-flex justify-content-between">
              <div className="media">
                <i className="fa fa-magic mr-4"></i>
                <div className="media-body">
                  <h5>Desarrollo a Medida</h5>
                  Sitios web adaptados a tus necesidades.
                </div>
              </div>
              <div className="media">
                <i className="fa fa-magic mr-4"></i>
                <div className="media-body">
                  <h5>Soporte Técnico</h5>
                  Mantenimiento y actualizaciones constantes.
                </div>
              </div>
            </div>
            <a href="/#" className="btn btn-ngDesign">
              Ver Mas
            </a>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 d-flex align-items-center justify-content-center aos-animate-right">
            <img className="featured-big " src={escritorio} alt="Featured 1" loading="lazy" />
          </div>
        </div>
      </div>
    </section>
  );
}
