import React from "react";
import './footer.css';


import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Footers() {


  return (
    <footer className="footer">
      <div className="footer-widgets">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col1">
              <div className="single-widget contact-widget">
                <h6 className="widget-tiltle">Contacto</h6>
                <p>
                  Suscríbete a nuestra lista de correos para recibir las últimas noticias y actualizaciones.
                </p>
                <div className="media">
                  <i className="fa fa-map-marker"></i>
                  <div className="media-body ml-3">
                    <h6>Dirección</h6>
                   Caracas esq Rivera, Montevideo, Uruguay
                  </div>
                </div>
                <div className="media">
                  <i className="fa fa-envelope-o"></i>
                  <div className="media-body ml-3">
                    <h6>¿Tienes preguntas?</h6>
                    <a href="mailto:contacto@tusitio.com">ngunaris@gmail.com</a>
                  </div>
                </div>
                <div className="media">
                  <i className="fa fa-phone"></i>
                  <div className="media-body ml-3">
                    <h6>Contáctanos</h6>
                    <a href="/#">+598 96 766880</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col2">
              <div className="single-widget tags-widget">
                <h6 className="widget-tiltle">Etiquetas Populares</h6>
                <a href="/#">Diseño</a>
                <a href="/#">Arte</a>
                <a href="/#">Programación</a>
                <a href="/#">React</a>
                <a href="/#">UI/UX</a>
              </div>
              <div className="single-widget subscribe-widget">
                <h6 className="widget-tiltle">Suscríbete</h6>
                <p>Regístrate para recibir nuestras últimas actualizaciones y ofertas.</p>
                <form method="get">
                  <div className="input-group">
                    <input
                      className="field form-control"
                      name="subscribe"
                      type="email"
                      placeholder="Correo Electrónico"
                    />
                    <span className="input-group-btn">
                      <button type="submit" name="submit-mail">
                        <i className="fa fa-check"></i>
                      </button>
                    </span>
                  </div>
                </form>
                <p>Respetamos tu privacidad.</p>
                <ul className="nav social-nav">
                  <li><a href="/#"><i className="fa fa-facebook"></i></a></li>
                  <li><a href="/#"><i className="fa fa-twitter"></i></a></li>
                  <li><a href="/#"><i className="fa fa-linkedin"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Nota al pie */}
      <div className="foot-note">
        <div className="container">
          <div className="footer-content text-center d-lg-flex justify-content-between align-items-center">
            <p className="mb-0">
              © {new Date().getFullYear()} Todos los derechos reservados. Diseñado por 
              <a href="https://ngdesignstudio.com" target="_blank" className="fh5-link" rel="noreferrer"> NgDesign</a>.
            </p>
            <p className="mb-0">
              <a href="/#">Términos de Uso</a>
              <a href="/#">Privacidad</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
