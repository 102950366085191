import { createContext, useState } from "react";

export const HamburgerContext = createContext();

export function HamburgerProvider({ children }) {
  const [isClicked, setIsClicked] = useState(false);

  const toggleMenu = () => {
    setIsClicked(!isClicked);
  };

  return (
    <HamburgerContext.Provider
      value={{
        isClicked,
        setIsClicked,
        toggleMenu
      }}
    >
      {children}
    </HamburgerContext.Provider>
  );
}
