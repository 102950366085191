import React, { useEffect, useState } from "react";
import "./hero.css";

import img1 from "../../../img/escritorio-ng.jpg";
import img2 from "../../../img/ipad-ng2.jpg";
import img3 from "../../../img/iphone-ng2.jpg";

const imagenes = [{ imagen: img1 }, { imagen: img2 }, { imagen: img1 }];

export default function Hero() {


  const handleHover = (e) => {
    const ipad = document.querySelector(".ipad");
    const iphone = document.querySelector(".iphone");
    const laptop = document.querySelector(".Laptop");

  
    // Remover todas las clases primero
    ipad.classList.remove("zoom", "far");
    iphone.classList.remove("zoom", "far");
    laptop.classList.remove("zoom", "far");
  
  
    // Agregar clases según el elemento que se pasó por hover
    const target = e.currentTarget;
    target.classList.add("zoom");

    if (target === ipad) {
      iphone.classList.add("far");
      laptop.classList.add("far");
    } else if (target === iphone) {
      ipad.classList.add("far");
      laptop.classList.add("far");
    } else if (target === laptop) {
      ipad.classList.add("far");
      iphone.classList.add("far");
    }
  };
  
  const handleLeave = () => {
    // Restaurar todas las clases cuando el mouse sale
    document.querySelectorAll(".ipad, .iphone, .Laptop").forEach((el) => {
      el.classList.remove("zoom", "far");
    });
  };
  

  useEffect(() => {
    const handleScrollHero = () => {
      const title = document.querySelectorAll(".encabezado-hero");
      const elementos = document.querySelectorAll(".hero_");

      const windowHeight = window.innerHeight;

      elementos.forEach((elemento, index) => {
        const rect = elemento.getBoundingClientRect();
        const isInCenter =
          rect.top <= windowHeight / 1.3 && rect.bottom >= windowHeight / 1.3;

        if (isInCenter && !elemento.classList.contains("animated")) {
          elemento.style.animation = `apareceHero 1200ms ease-in-out forwards`;
          elemento.style.animationDelay = `${index * -200}ms`;
          elemento.classList.add("animated");
        }
      });

      title.forEach((titulos, index) => {
        const rect = titulos.getBoundingClientRect();
        const isInCenter =
          rect.top <= windowHeight / 1.3 && rect.bottom >= windowHeight / 1.3;

        if (isInCenter && !titulos.classList.contains("animated")) {
          titulos.style.animation = `apareceHeroTitle 1000ms ease-in-out forwards`;
          titulos.style.animationDelay = `${index * -200}ms`;
          titulos.classList.add("animated");
        }
      });
    };
    window.addEventListener("scroll", handleScrollHero);
    return () => {
      window.removeEventListener("scroll", handleScrollHero);
    };
  }, []);

  return (
    <div className="hero">
      <div className="encabezado-hero">
        <h2>"Diseño Adaptado a Todas las Pantallas"</h2>
        <p>
          Tu sitio web se verá increíble en cualquier dispositivo: computadoras,
          tablets y smartphones. Optimizamos cada detalle para una experiencia
          fluida y atractiva en todas las plataformas.
        </p>
      </div>
      <div className="hero_">
        {/* Laptop */}
        <div
          data-layer="Laptop"
          className="Laptop "
          onMouseEnter={handleHover} onMouseLeave={handleLeave}
        >
          <div data-layer="MacBook Air - 1" className="MacbookAir1">
            <div data-layer="MacBook Air - 2" className="MacbookAir2">
              <img src={img1}></img>
            </div>
          </div>
          <div data-layer="Rectangle 1" className="Rectangle1" />
        </div>

        {/* iPad */}
        <div className="ipad " onMouseEnter={handleHover} onMouseLeave={handleLeave}>
          <div className="marco">
            <div data-layer="iPadScreen" className="IpadScreen">
              <div data-layer="Ellipse1" className="Ellipse1" />
              <img src={img2} alt="/#" className="ipadImg"></img>
            </div>
          </div>
        </div>

        {/* iPhone */}
        <div className="iphone" onMouseEnter={handleHover} onMouseLeave={handleLeave}>
          {" "}
          <div data-layer="iPhone" className="marcoIphone">
            <div data-layer="iphoneScreen" className="iphoneScreen">
              <div data-layer="EllipseIphone" className="EllipseIphone" />
              <img src={img3} alt="/#"></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
