import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";
import Home from "./componentes/home/Home";
import { HamburgerProvider } from "./context/HamburgerContext";
import Contacto from "./componentes/contacto/Contacto";


function App() {
  return (
    <div className="App">
      <HamburgerProvider>
        <Router>
          <Routes>
            <Route index element={<Home />} />
            <Route path="/contacto" element={<Contacto />} />
          </Routes>
        </Router>
      </HamburgerProvider>
    </div>
  );
}

export default App;
