import React, { useEffect } from "react";

import "./services.css";


import seo from '../../../img/seo.png'
import disenoGrafico from '../../../img/disenador-grafico.png'
import sitioWeb from '../../../img/sitio-web.png'
import software from '../../../img/software.png'
import mantenimiento from '../../../img/mantenimiento.png'
import carritoDeCompras from '../../../img/carrito-de-comprasColor.png'
import hosting from '../../../img/reparacion-de-maquinas.png'

export default function Services() {
  const servicios = [
    {
      imagen: sitioWeb,
      h5: "Desarrollo Web",
      p: "Creación de sitios web modernos y responsivos utilizando tecnologías como React, Node.js y MongoDB.",
    },
    {
      imagen: carritoDeCompras,
      h5: "Tiendas Online",
      p: "Diseño y desarrollo de e-commerce personalizados con integración de pagos y panel de administración.",
    },
    {
      imagen: disenoGrafico,
      h5: "Diseño Gráfico",
      p: "Diseños creativos y personalizados para logos, banners, redes sociales y branding completo.",
    },
    {
      imagen: seo,
      h5: "Optimización SEO",
      p: "Mejoramos la visibilidad de tu sitio en los motores de búsqueda para atraer más tráfico orgánico.",
    },
    {
      imagen: mantenimiento,
      h5: "Mantenimiento Web",
      p: "Actualización, optimización y resolución de problemas técnicos en tu sitio web.",
    },
    {
      imagen: hosting,
      h5: "Dominios",
      p: "Dominios y certificados SSL para garantizar la mejor experiencia.",
    },
  ];

  
  useEffect(() => {
    const handleScroll = () => {
      const title = document.querySelectorAll(".title");
      const elementos = document.querySelectorAll(".segmento");
      const windowHeight = window.innerHeight;
      elementos.forEach((elemento, index) => {
        const rect = elemento.getBoundingClientRect();
        const isInCenter =
          rect.top <= windowHeight / 1.3 && rect.bottom >= windowHeight / 1.3;

        if (isInCenter && !elemento.classList.contains("animated")) {
          elemento.style.animation = `aparece 1200ms ease-in-out forwards`;
          elemento.style.animationDelay = `${index * -200}ms`;
          elemento.classList.add("animated");
        }
      });

      title.forEach((titulos, index) => {
        const rect = titulos.getBoundingClientRect();
        const isInCenter =
          rect.top <= windowHeight / 1.3 && rect.bottom >= windowHeight / 1.3;

        if (isInCenter && !titulos.classList.contains("animated")) {
          titulos.style.animation = `apareceHeroTitle 1000ms ease-in-out forwards`;
          titulos.style.animationDelay = `${index * -200}ms`;
          titulos.classList.add("animated");
        }
      });
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className="services container-flex" id="servicios">
      <div className="container">
        <div className="title text-center">
          <h6>Nuestros Servicios</h6>
          <h1 className="title-blue">Porque Elegirnos?</h1>
        </div>
        <div className="container">
          <div className="row">
            {servicios.map((servicio, index) => (
              <div className="col-sm-6 col-lg-4" key={index}>
                <div
                  className="media aos-init segmento"
                  style={{ animationDelay: `${index * 500}ms` }} // Retraso dinámico en CSS
                >
                  <img
                    className="mr-4"
                    src={servicio.imagen}
                    alt={servicio.h5}
                    loading="lazy"
                  />
                  <div className="media-body">
                    <h5>{servicio.h5}</h5>
                    <p>{servicio.p}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
