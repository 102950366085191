import React, { useEffect } from "react";

import "./tablaDePrecios.css";
import { WhatsAppButton } from "../whatsapp/Whatsapp";

export default function TableDePrecios() {

  const phoneNumber = "59891799403";
  const message = "Hola, me gustaría obtener más información sobre sus servicios.";
  const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
    message
  )}`;
  const precios = [
    {
      titulo: "Mensual",
      plan: "Standard",
      //precio: "US$49",
      descripcion: "Ideal para emprendedores",
      p1: "Diseño web a medida",
      p2: "Hasta 6 secciones dinamicas",
      p3: "Links a redes sociales",
      p4: "Optimización SEO básica",
    },
    {
      titulo: "Mensual",
      plan: "Tienda Online",
     // precio: "US$89",
      descripcion: "Venta directa de productos y servicios",
      p1: "Diseño de sitio web responsivo",
      p2: "Soporte todos los dias",
      p3: "Carga Inicial de Productos",
      p4: "Soporte de catalogo de productos",
    },
    {
      titulo: "Mensual",
      plan: "Corporativo",
     // precio: "US$99",
      descripcion: "Para empresas establecidas y/o en crecimiento",
      p1: "Diseño web a medida",
      p2: "Integración con e-commerce",
      p3: "SEO avanzado",
      p4: "Correos corporativos",
    }
  ];

  useEffect(() => {
    const handleScrollPrecio = () => {
      const elementos = document.querySelectorAll(".precioCard");
      const windowHeight = window.innerHeight;
      elementos.forEach((elemento, index) => {
        const rect = elemento.getBoundingClientRect();
        const isInCenter =
          rect.top <= windowHeight / 1.3 && rect.bottom >= windowHeight / 1.3;

        if (isInCenter && !elemento.classList.contains("animated")) {
          elemento.style.animation = `apareceDesdeIzq 1200ms ease-in-out forwards`;
          elemento.style.animationDelay = `${index * -200}ms`;
          elemento.classList.add("animated");
        }
      });
    };
    window.addEventListener("scroll", handleScrollPrecio);
    return () => {
      window.removeEventListener("scroll", handleScrollPrecio);
    };
  }, []);

  return (
    <section className="pricing-table" id="precios">
      <div className="container">
        <div className="title text-center">
          <h6 className="title-small">Precios</h6>
          <h2 className="title-blue">Paquetes de Servicios</h2>
        </div>
        <div className="row no-gutters">
          {precios.map((precio, index) => (
            <div
              className="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4 precioCard"
              key={index}
            >
              <div className="single-pricing text-center">
                <span>{precio.titulo}</span>
                <h2>{precio.plan}</h2>
                <p className="desc">{precio.descripcion}</p>
                <p className="price">{precio.precio}</p>
                <p>{precio.p1}</p>
                <p>{precio.p2}</p>
                <p>{precio.p3}</p>
                <p>{precio.p4}</p>
                <a href={whatsappLink} className="btn btn-ngDesign">
                  Contratar
                </a>
                <svg viewBox="0 0 170 193">
                  <path
                    fill-rule="evenodd"
                    fill="rgb(167, 164, 164)"
                    d={precio.d}
                  ></path>
                </svg>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
