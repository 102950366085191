import React from "react";
import Header from "../subComponentes/header/Header";
import Hero from "../subComponentes/hero/Hero";
import Services from "../subComponentes/services/Services";
import Featured from "../subComponentes/featured/Featured";
import Trust from "../subComponentes/trust/Trust";
import TableDePrecios from "../subComponentes/tabla-de-precios/TableDePrecios";
import Testimonios from "../subComponentes/testimonios/Testimonios";
import Footers from "../subComponentes/footer/Footers";
import Whatsapp, { WhatsAppButton } from "../subComponentes/whatsapp/Whatsapp";

import "./home.css";
import RecenPosts from "../subComponentes/recent-post/RecenPosts";

export default function Home() {
  
  return (
    <div className="home container-flex">
      <Header />
      <Featured />
      <Services />
      <Hero />
      <TableDePrecios />
      <Footers />
      <WhatsAppButton />
    </div>
  );
}
